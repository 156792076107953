import { SyntheticEvent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Show,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';

// Components
import Icon from '@/components/Icon';
import { SUBSCRIBE_EMAIL } from '@/constants/analytics';
import { PRIVACY_POLICY_URL } from '@/constants/constants';
import { StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';
import { CheckMark } from '@/icons';
import { subscribeEmail } from '@/services/app';
import {
  chainSubscribeKeyMapping,
  getCurrentChain,
  validateEmail,
} from '@/utils/common';

import Section from '../Section';

interface NewsletterProps {
  sectionDetails: StrapiSectionDetail;
  subscribeForm: {
    placeholder?: string;
    submit_text?: string;
  };
}

const Newsletter = ({ sectionDetails, subscribeForm }: NewsletterProps) => {
  const pathname = usePathname();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();

  const token = getCurrentChain(pathname);

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError('');
      } else {
        setError('Please enter correct email ID');
      }
    }
  }, [email]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    trackEvent(SUBSCRIBE_EMAIL, { email_id: email });
    if (!validateEmail(email)) {
      setError('Please enter correct email ID');
      return;
    }
    if (token && chainSubscribeKeyMapping[token]) {
      setIsSubscribing(true);

      const formData: any = new FormData();

      formData.append('Email', email);
      formData.append(chainSubscribeKeyMapping[token], true);

      const response = await subscribeEmail(formData);

      if ((response as any).error) {
        toast({
          description: 'Failed to subscribe',
          status: 'error',
          duration: 2000,
          position: 'top',
          isClosable: true,
        });
        setIsSubscribing(false);
        setEmail('');
        return;
      }

      toast({
        description: 'Email subscribed successfully',
        status: 'success',
        duration: 2000,
        position: 'top',
        isClosable: true,
      });
      setIsSubscribing(false);
      setEmail('');
    }
  };

  return (
    <>
      <Section
        title={sectionDetails.title ?? undefined}
        subTitle={sectionDetails.sub_title ?? undefined}
        id={sectionDetails.section_id ?? undefined}
        py={{ base: '64px', lg: '160px' }}
      >
        <Box px={{ base: 4, lg: 0 }}>
          <Center mt={5} flexDir="column">
            <form onSubmit={handleSubmit}>
              <InputGroup
                size="lg"
                mt="0.5rem"
                mb="0"
                w={{ base: '320px', lg: '548px' }}
              >
                <Input
                  bg="backgroundPrimary"
                  type="email"
                  required
                  placeholder={subscribeForm?.placeholder}
                  value={email}
                  fontSize="1rem"
                  fontWeight="500"
                  h={{ base: '56px', xl: '58px' }}
                  pl="2rem"
                  borderRadius="10px"
                  border="1px solid rgba(20, 37, 40, 0.1)"
                  onChange={(e) => setEmail(e.target.value)}
                  _placeholder={{ color: '#1425284D' }}
                />

                <InputRightElement h="58px" w="128px" mr="5px" zIndex="0">
                  <Show above="xl">
                    <Button type="submit" p="11px 1.5rem" h={46}>
                      {isSubscribing ? (
                        <Spinner size="md" color="white" />
                      ) : (
                        <Text color="white">{subscribeForm?.submit_text}</Text>
                      )}
                    </Button>
                  </Show>
                </InputRightElement>
              </InputGroup>
              {error && (
                <Text textStyle="p14" color="#e53e3e" mt="0.5rem">
                  {error}
                </Text>
              )}
              <Show below="xl">
                <Center>
                  <Button
                    w="152px"
                    type="submit"
                    mt="1.5rem"
                    h="40px"
                    p="10px 2.5rem"
                  >
                    {isSubscribing ? (
                      <Spinner size="md" color="white" />
                    ) : (
                      <Text color="white">Subscribe</Text>
                    )}
                  </Button>
                </Center>
              </Show>
            </form>
            <Center
              mt="1.5rem"
              flexDir={{ base: 'column', xl: 'row' }}
              fontSize={{ base: '14px', xl: '16px' }}
              alignItems="center"
              fontWeight="500"
            >
              <Flex gap="4px">
                <Icon height="24px" width="24px" Icon={CheckMark} />
                <Text color="#8F8F8F">By subscribing you accept our</Text>
              </Flex>
              <Link
                ml={{ base: 0, xl: '0.5rem' }}
                mt={{ base: '2px', xl: 0 }}
                variant="secondary"
                color="primary"
                cursor="pointer"
                href={PRIVACY_POLICY_URL}
                isExternal={true}
                borderBottom="1px dashed"
                borderColor="primary"
                textDecor="none !important"
              >
                Privacy policy
              </Link>
            </Center>
          </Center>
        </Box>
      </Section>
    </>
  );
};

export default Newsletter;
