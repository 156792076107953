import { captureException } from '@sentry/nextjs';

import {
  SENTRY_TAGS,
  SENTRY_TAGS_TYPE_VALUES,
} from '@/constants/monitoring.constants';

export const subscribeEmail = async (data: FormData) => {
  try {
    const response = await fetch(
      'https://script.google.com/macros/s/AKfycbxdo569bZMkTsQHuE3qFzO2dEwpoyOly6MbAlVUU2YlM-kMYUxcUMGYxL1_cksL1UsiPQ/exec',
      {
        method: 'POST',
        body: data,
        redirect: 'follow',
      },
    );

    return response;
  } catch (error) {
    captureException(error, {
      contexts: {
        values: { data },
      },
      tags: {
        [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.GENERAL,
        [SENTRY_TAGS.MODULE]: 'subscribeEmail',
        [SENTRY_TAGS.OPERATION]: 'subscribeEmail',
      },
    });
    return {
      error,
    };
  }
};
